import React from "react";
import {Router} from "@reach/router"
import EmpleosLayout from "../../components/empleos/EmpleosLayout";
import EmpleosDynamicDetail from "../../components/empleos/EmpleosDynamicDetail";

const pageData = {
    title: 'Trabaja con nosotros',
    description: 'Te presentamos un resumen diario de los eventos más relevantes del mercado. Mantenete informado y potenciá tu capital.'
};

const SearchesIndex = () => {

    return (
        <EmpleosLayout pageData={pageData}>
            <Router basepath="/trabaja-con-nosotros">
                <EmpleosDynamicDetail path="/:slug"/>
            </Router>
        </EmpleosLayout>
    );
};

export default SearchesIndex;